<template>
	<Toast />
	<div class="card p-grid p-fluid" v-if='false' style="margin-bottom: 0px;">
		<!-- 各种方法的集合库 -->
	</div>
</template>

<script>
	import NetWorkService from '../service/NetWorkService.js';
	export default {
		name: 'Utils',
		props: {
			para_copy: {
				default: null,
				type: Object
			}
		},
		components: {

		},
		data() {
			return{
				
			}
		},
		networkService:null,
		created() {
			this.networkService = new NetWorkService();
		},
		mounted() {
			// console.log('组件加载成功');
		},
		methods: {
			//邮箱错误提示
			showError(msg) {
				this.$toast.add({
					severity: 'error',
					summary: '失败',
					detail: msg,
					life: 5000
				});
			},
			//展示成功信息
			showSusscessInfo(msg){
				this.$toast.add({
					severity: 'success',
					summary: '成功',
					detail: msg,
					life: 3000
				});
			},
			//开始处理
			startProcessing(){
				this.$appState.processing=true;
			},
			//停止处理
			stopProcessing(){
				this.$appState.processing=false;
			},
			//查询单词
			async autoTaged(text_list) {
				// console.log('自动标注处理数据',text_list);
				this.startProcessing();
				var data = new URLSearchParams();
				data.append("original_text_list", JSON.stringify(text_list));
				var url = '/taged/auto-taged';
				// console.log("请求开始");
				var resp = await this.networkService.postService(url, data);
				this.stopProcessing();
				// console.log("返回值",resp);
				return resp;
			},
			async saveTaged(file_taged,file_name) {
				this.startProcessing();
				var data = new URLSearchParams();
				data.append("file_taged", file_taged);
				data.append("file_name", file_name);
				var url = '/taged/save-taged';
				// console.log("请求开始");
				var resp = await this.networkService.postService(url, data);
				this.stopProcessing();
				// console.log("返回值",resp);
				return resp;
			},
			async getStatisdics(file_taged) {
				this.startProcessing();
				var data = new URLSearchParams();
				data.append("file_taged", file_taged);
				var url = '/taged/taged-statisdics';
				// console.log("请求开始");
				var resp = await this.networkService.postService(url, data);
				this.stopProcessing();
				// console.log("返回值",resp);
				return resp;
			},
			async getCorpusList() {
				this.startProcessing();
				var url = '/taged/corpus-list';
				// console.log("请求开始");
				var resp = await this.networkService.postService(url,{});
				this.stopProcessing();
				// console.log("返回值",resp);
				return resp;
			},
			async getCorpusInfo(corpus_meta) {
				this.startProcessing();
				var url = '/taged/corpus-info';
				var data = new URLSearchParams();
				data.append("corpus_meta", JSON.stringify(corpus_meta));
				var resp = await this.networkService.postService(url,data);
				this.stopProcessing();
				return resp;
			},
			async deleteCorpus(corpus_meta) {
				this.startProcessing();
				var url = '/taged/delete-corpus';
				var data = new URLSearchParams();
				data.append("corpus_meta", JSON.stringify(corpus_meta));
				var resp = await this.networkService.postService(url,data);
				this.stopProcessing();
				return resp;
			},
		},
	}
</script>

<style scoped lang="scss">
</style>
