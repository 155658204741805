<template>
	<Toast />
	<div v-show='show_select_card' class='card translate-card p-shadow-4' id='blackSquare'>
		<h5 style="margin-top:auto;"><Badge severity="primary" title='当前操作的实体数量' style='margin-right: 10px;margin-top:auto;' :value='choose_number' class="mr-2"></Badge>标注类型<i class="pi pi-times close-processing" @click='closeTagedCard' title='关闭标注窗' style="margin-right: 0px;margin-top: auto;"></i></h5>
		<WordFilterInput style='margin-bottom: 0px;' ref='selectClass' :show_list_copy='false' @selectGisClass='selectGisClass' :filter_input_id_copy="'select_class'" :show_name_copy="'name_code'" :title_name_copy="'definition'"
			:op_status_copy="'select_class'" v-if='item_class_list.length!==0' :list_copy='item_class_list' :selected_words_copy="''" />
		<div style="padding: 0.3rem;">
			<Tag class="mr-3" style='margin-top: 10px;' @click='selectGisClass(item,j,1)' severity="success" :title='item.definition' :value="item.name_code" v-for="(item, j) of latest_ten_class" :key="j"></Tag>
			<Tag class="mr-3" style='margin-top: 10px;' @click='selctOtherClass(item)' severity="warning" :title='item.definition' :value="item.name" v-for="(item, j) of other_class" :key="j"></Tag>
		</div>
		<Divider style="margin-top: 20px;" layout="horizontal" align="center">
			<span class="p-tag">要素关系属性</span>
		</Divider>
		<div class="grid" style="padding: 0.3rem;">
			<div class="field-checkbox mb-0" style="margin: 10px;" v-for="(item, j) of item_relation_attribute" :key="j">
				<Checkbox id="checkOption1" name="option" :value="item" v-model="checkboxValue" />
				<label for="checkOption1">{{item}}</label>
			</div>
			<Tag class="mr-3" style='margin-top: 10px;' @click='confirmRelation' severity="info" value="确定"></Tag>
		</div>
	</div>
</template>

<script>
	import ReadFileService from '../service/ReadFileService.js';
	import WordFilterInput from './WordFilterInput.vue';
	export default {
		name: '',
		props: {
			choose_number_copy: {
				default: null,
				type: String,
			}
		},
		components: {
			'WordFilterInput': WordFilterInput,
		},
		computed: {
			show_select_card() {
				return this.$appState.show_select_card;
			}
		},
		data() {
			return {
				choose_number:0,
				local_storage_class_name:'kg_class',
				item_class_list:[],
				item_relation_attribute:[
					'函数',
					'反函数',
					'传递',
					'对称',
					'非对称',
					'自反',
					'非自反',
				],
				latest_ten_class:[],
				other_class:[
					{
						"code": "topic_code",
						"type": "topic_class",
						"name": "专题实体",
						"name_code": "专题实体_topic_code",
						"definition": "除了导入的要素类别之外都算专题实体",
					},
					{
						"code": "time_code",
						"type": "time_class",
						"name": "时间实体",
						"name_code": "时间实体_time_code",
						"definition": "用于表达时间的实体",
					},
				],
				checkboxValue: [],
			}
		},
		created() {
			this.ReadFileService = new ReadFileService();
		},
		mounted() {
			this.readClassFromLocalstorage();
		},
		methods: {
			flushItemClassList(new_list,file_type){
				switch (file_type) {
					case 'add_class':
						this.addItemClassList(new_list);
						break;
					case 'fresh_class':
						this.freshItemClassList(new_list);
						break;
					default:
						console.log('未指定相应函数');
				}
			},
			freshItemClassList(new_list){
				this.item_class_list=[];
				var that=this;
				setTimeout(() => {
					that.item_class_list=new_list;
					that.saveClassToLocalstorage();
				}, 1000)
			},
			addItemClassList(add_list){
				for (var i=0;i<add_list.length;i++){
					this.item_class_list.push(add_list[i]);
				}
				this.saveClassToLocalstorage();
			},
			saveClassToLocalstorage(){
				var content=JSON.stringify(this.item_class_list);
				localStorage.setItem(this.local_storage_class_name, content);
			},
			readClassFromLocalstorage(){
				if (localStorage.getItem(this.local_storage_class_name) !== null) {
					var this_file_data = JSON.parse(localStorage.getItem(this.local_storage_class_name));
					if (this_file_data.length==0){
						this.readFileFromJson();
					}else{
						this.item_class_list=this_file_data;
					}
				} else {
					this.readFileFromJson();
				}
				
				//从缓存加载最近选择的实体
				if (localStorage.getItem('latest_ten_class') != null) {
					this.latest_ten_class= JSON.parse(localStorage.getItem("latest_ten_class"));
				}
			},
			readFileFromJson(){
				//读取数据
				// console.log('读取要素类别数据');
				this.ReadFileService.getGISClass().then(data =>{
					// console.log('读取到要素类别数据',data);
					this.item_class_list=[];
					// this.saveClassToLocalstorage();
					var that=this;
					setTimeout(() => {
						that.item_class_list=data.class_list;
						that.saveClassToLocalstorage();
					}, 1000)
				});
			},
			freshSelectNumber(this_number){
				this.choose_number=this_number.toString();
			},
			closeTagedCard() {
				this.$appState.show_select_card = false;
				//删除标记
				this.$emit('cancelTag');
			},
			selctOtherClass(code){
				// console.log('选择的属性类别',code);
				this.$emit('confirmTag',code);
			},
			selectGisClass(item,index,update_lateset_ten){
				this.$emit('confirmTag',item);
				if(update_lateset_ten==0){
					this.latest_ten_class.unshift(item);
					if(this.latest_ten_class.length>5){
						this.latest_ten_class=this.latest_ten_class.slice(0,5);
					}
				}else{
					this.latest_ten_class.splice(index,1);
					this.latest_ten_class.unshift(item);
				}
				//写入缓存
				var content = JSON.stringify(this.latest_ten_class);
				localStorage.setItem('latest_ten_class', content);
			},
			confirmRelation(){
				if (this.checkboxValue.length==0){
					this.$toast.add({
						severity: 'error',
						summary: 'Error Message',
						detail: '请选择至少一个关系属性',
						life: 3000
					});
					return;
				}
				var tag_item={
					"code": "关系属性",
					"type": "relation_class",
					"name": "要素关系",
					"name_code": "要素关系_relation_code",
					"definition": "",
				}
				var all_relation=this.checkboxValue[0];
				for (var i=1;i<this.checkboxValue.length;i++){
					all_relation+='、'+this.checkboxValue[i];
				}
				tag_item.definition=all_relation;
				this.$emit('confirmTag',tag_item);
				// console.log('标注对象',tag_item);
			}
		},
	}
</script>

<style scoped lang="scss">
	.translate-card {
		z-index: 100000;
		width: 350px;
		// position: fixed;
		position: absolute;
		top:50px;
		height: 400px;
		overflow-y: auto;
	}

	.close-processing {
		fontSize: 0.5rem;
		float: right;
		margin-left: 20px;
		border-radius: 50%;
		padding: 3px;
		// background-color: red;
		background-color: var(--primary-color);
		color:#ffffff;
		margin-top: 10px;
		margin-right: 10px;
	}
	
	.title-item{
		display: flex; 
		align-items: center;
		justify-content: space-between;
	}
	
	.initial-ori-text{
		margin-left: 10px;
		padding: 3px;
		border-radius: 50%;
		// background-color: var(--primary-color);
		// color:#ffffff;
		float: right;
		margin-right: 10px;
	}
</style>
